$size-largest: 78.5em; // 1256px
$size-largest-medium: 65.9375em; // 1055/16 = 56.25em
$size-largest-small: 60.4375em; // 967
$size-medium: 55.8125em; // 893
$size-medium-small: 42.4375em; // 679
$size-small: 29.3125em; // 469

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  //styles applied below this size

  @media only screen and (max-width: $size-largest-small) {
    font-size: 50%; // rem take this px size, here it is 8px and 1rem =  8px
  }
}

body {
  //Use Open Sans or sans-serif
  font-family: "Palanquin", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: $color-primary;
  min-height: 100vh;
}
