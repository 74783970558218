.head-section {
  margin: 2rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $size-small) {
    padding: 0;
  }
}

.logo {
  max-width: 10rem;
  max-height: 10rem;
  @media only screen and (max-width: $size-small) {
    max-width: 7rem;
    max-height: 7rem;
  }
}

.header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;

  &__title {
    padding: 2rem;
    font-size: 5rem;
    font-weight: 700;
    font-family: "Tienne", serif;
  }

  &__description {
    padding: 2rem;
    font-weight: 300;
    font-size: 3rem;
  }
}

.my-image {
  margin-top: 6rem;
  width: 26rem;
  height: 26rem;
  border-radius: 50%;

  @media only screen and (max-width: $size-largest-medium) {
    width: 20rem;
    height: 20rem;
  }
}

.small-image {
  width: 10rem;
  height: 10rem;
}

.hat {
  padding: 1rem;
  max-width: 86rem;
  max-height: 86rem;
  position: relative;
  left: 10rem;
  top: -9rem;

  @media only screen and (max-width: $size-largest-medium) {
    max-width: 74rem;
    max-height: 74rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: $size-largest-small) {
    left: 6rem;
  }

  @media only screen and (max-width: $size-medium-small) {
    max-width: 50rem;
    max-height: 50rem;
    left: 0;
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: $size-small) {
    max-width: 40rem;
    max-height: 40rem;
    margin-top: 2rem;
  }
}

.border-bottom {
  border-bottom: 1px solid #e6ecf8;
}

.contact-first-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -11rem;
}

.title {
  color: black;
  font-size: 4rem;
  font-weight: 300;
  width: 60%;
  padding: 0 1rem;
  margin: 3rem 2rem;

  @media only screen and (max-width: $size-small) {
    width: 95%;
  }
}

.no-padding {
  padding: 0;
}
