.footer {
  background-color: $color-primary;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-project {
  max-width: 104rem;
  border-radius: 2rem;
  background-color: #062727;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  margin: -8rem 2rem 0;

  @media only screen and (max-width: $size-medium-small) {
    flex-direction: column;
  }

  &__header {
    font-size: 4rem;
    font-weight: 700;
    padding: 1rem;
  }

  &__description {
    font-weight: 300;
    font-size: 2rem;
    padding: 1rem;
    flex: 0.6;
    text-align: center;
  }
}

.footer-container {
  margin: 10rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 7rem;
    height: 10rem;
    margin: 2rem 0 2rem;
  }

  &__quote {
    font-size: 3rem;
    font-weight: 300;
    color: white;
    max-width: 37%;
    text-align: center;
    margin: 2rem;

    @media only screen and (max-width: $size-small) {
      max-width: 80%;
    }
  }

  &__social-icons-list {
    display: flex;
    justify-content: space-between;
  }

  &__icons {
    margin: 2rem;
  }
}

.creator {
  color: white;
  font-size: 2rem;
  font-weight: 300;
  margin-top: 4rem;
}
