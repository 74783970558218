.skills {
  position: relative;
  top: -40rem;

  &__logo {
    width: 12rem;
    height: 9rem;
  }

  &__header {
    font-family: "Tienne", serif;
    color: black;
    font-size: 2rem;
    font-weight: 700;
    margin: 3rem;
    color: black;
  }

  &__description {
    margin: 1rem;
    color: black;
    font-weight: 300;
    font-size: 2rem;
  }

  &__header-medium {
    font-size: 2rem;
    font-weight: 500;
  }
}

.box {
  background-color: white;
  margin: 3rem;
  border: 1px solid #e6ecf8;
  box-shadow: 0 5px 5px 0 #e6ecf8;
  border-radius: 2rem;
  display: flex;

  @media only screen and (max-width: $size-medium) {
    flex-direction: column;
    align-items: center;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 5rem;
  &:not(:last-child) {
    border-right: 1px solid #e6ecf8;
    @media only screen and (max-width: $size-medium) {
      border-right: none;
    }
  }

  @media only screen and (max-width: $size-medium) {
    padding: 6rem 5rem;

    &:not(:first-child) {
      border-top: 1px solid #e6ecf8;
    }
  }
}

.domian {
  padding: 1rem;
  text-align: center;
}

.area {
  padding: 2rem;
  text-align: center;
}

.tolls {
  padding: 1rem;
  text-align: center;
}

ul {
  list-style-type: none;
}

.work {
  padding: 2rem;
  color: black;
  position: relative;
  //margin-top instead of top to avoid unwanted bottom space
  margin-top: -20rem;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $size-small) {
    margin-top: -34rem;
  }

  &__title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin: 2rem;
  }

  &__second-title {
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    margin: 0 0 5rem;
  }
}

.work-cards {
  margin-bottom: 20rem;
}

.card {
  border: 1px solid #e6ecf8;
  box-shadow: 0 5px 5px 0 #e6ecf8;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem;

  @media only screen and (max-width: $size-largest) {
    padding: 3rem;
  }

  &__image {
    margin: 1rem;
    width: 15rem;
    height: 10rem;
  }

  &__description {
    margin: 1rem;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    color: black;
  }

  &__link {
    margin: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: $color-primary;
    text-decoration: none;

    padding: 0.2rem 2.2rem;
    border: 3px solid $color-primary;
    border-radius: 3.2rem;
    transition: all 0.7s;

    @media only screen and (min-width: $size-medium-small) {
      &:hover {
        color: white;
        background-color: $color-primary;
      }
    }
  }
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
}

.col {
  width: 33.3333%;
  padding: 3rem;

  @media only screen and (max-width: $size-largest-small) {
    width: 50%;
  }

  @media only screen and (max-width: $size-small) {
    width: 100%;
  }
}
