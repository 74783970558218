.contact-button {
  padding: 0.2rem 1.2rem;
  text-decoration: none;
  font-size: 2rem;
  color: $color-primary;
  border: 3px solid $color-primary;
  border-radius: 3.2rem;
  transition: all 0.7s;

  @media only screen and (min-width: $size-medium-small) {
    &:hover {
      color: white;
      background-color: $color-primary;
    }
  }
}

.start-project__link {
  padding: 0.6rem 3.2rem;
  text-decoration: none;
  font-size: 2rem;
  color: white;
  border: 3px solid $color-primary;
  border-radius: 3.2rem;
  transition: all 0.7s;

  @media only screen and (min-width: $size-medium-small) {
    &:hover {
      background-color: $color-primary;
    }
  }

  @media only screen and (max-width: $size-medium-small) {
    margin: 2rem;
  }
}

.success {
  color: white;
  background-color: rgb(80, 168, 80);
  border: 3px solid rgb(80, 168, 80);

  padding: 0.2rem 1.2rem;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 3.2rem;
}

.failed {
  color: white;
  background-color: rgb(197, 42, 42);
  border: 3px solid rgb(197, 42, 42);

  padding: 0.2rem 1.2rem;
  text-decoration: none;
  font-size: 2rem;
  border-radius: 3.2rem;
}
