.container {
  max-width: 1344px;
  margin: 0 auto;
  text-align: center;
}

.line-height-small {
  @media only screen and (max-width: $size-medium-small) {
    line-height: 1.3 !important;
  }
}
